import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

ReactGA.initialize("G-KV1NGNZX9B");

ReactGA.send('page_view');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
