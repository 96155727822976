import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="logo">
        <img className="logo" src="/logo.png" />
      </div>
    </div>
  );
}

export default App;
